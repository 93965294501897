import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { NotFound } from './views/notfound'
import Login from './views/login'
import Main from './views/main'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/main" element={<Main />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
