import useLoading from '../config/useLoading.api'
import { useEffect } from 'react'
import { loadingIndicator, notify } from '../../../utils/notify'
import { getValidate, getVendor } from './merchant.api'

const useMerchantApi = () => {
  const { loading, on, off } = useLoading()

  const onValidate = (coupon, responseAction) => {
    on()
    getValidate(coupon)
      .then((r) => responseAction(r))
      .catch((r) => console.log(r))
      .finally(() => off())
  }

  const onVendor = (coupon, responseAction) => {
    on()
    getVendor(coupon)
      .then((r) => responseAction(r))
      .catch((r) => {
        console.log(r)
        notify('Coupon not found')
        responseAction(false)
      })
      .finally(() => off())
  }

  useEffect(() => {
    if (loading) {
      loadingIndicator()
    }
  }, [loading])
  return {
    loading,
    onVendor,
    onValidate,
  }
}
export default useMerchantApi
