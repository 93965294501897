import Swal from 'sweetalert2'

const popup = (description, showConfirm, confirmTitle, useTimer = true, allowClose = false) => {
  Swal.close()
  return Swal.fire({
    title: 'Notification',
    showCloseButton:true,
    showConfirmButton: showConfirm,
    confirmButtonText: confirmTitle,
    customClass: {
      htmlContainer: '!text-[14px] !pb-4',
      popup: 'rounded-2xl py-5 px-5',
      title: 'text-[16px]',
    },
    allowOutsideClick: allowClose,
    showClass: {
      popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `,
    },
    hideClass: {
      popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `,
    },
    timer: useTimer ? 1500 : null,
    html: description,
  })
}

export { popup }
