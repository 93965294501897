import React from 'react'

const Index = ({ title, onClick }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className="text-[18px] rounded-full pe-[15px] py-[18px] ps-[18px] text-white font-semibold w-full"
        style={{
          background: 'linear-gradient(180deg, #FF9B79 0%, #FD5014 100%)',
          boxShadow: '0px 4px 29px 0px #00000059',
        }}
      >
        {title}
      </button>
    </div>
  )
}

export default Index
