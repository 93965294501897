import React, { useState } from 'react'
import Wrapper from '../../components/wrapper'
import useMerchantApi from '../../service/api/merchant'
import { decryptMessage } from '../../utils'
import { popup } from '../../utils/popup'
import { API_URL } from '../../service/api/config'
import moment from 'moment'
import { notify } from '../../utils/notify'
import Logo from '../../assets/svgs/logo.svg'
import QrReader from 'react-qr-scanner'

const Index = () => {
  const [isCameraActive, setIsCameraActive] = useState(true)
  const merchantApi = useMerchantApi()
  const pathImage = (_path) => `${API_URL}/assets/${_path};`
  const previewStyle = {
    width: 300,
    height: 300,
    borderRadius: '1rem',
    objectFit: 'cover',
  }

  const handleError = (err) => {
    console.log(err)
  }

  const handleScan = (data) => {
    if (!!data) {
      setIsCameraActive(false)
      merchantApi.onVendor(data?.text, (r) => {
        if (!r) {
          setIsCameraActive(true)
          return
        }
        decryptMessage(r).then((res) => {
          const result = res.responseObject
          popup(
            PopupElement({
              brand: result.brand_name,
              logo: pathImage(result.brand_image),
              reward: result.reward_name,
              user: result.user_name,
              date: result?.claimed_at
                ? moment(result?.claimed_at).format('DD MMMM YYYY HH:mm')
                : '-',
              status: result.validate_by_staff,
              code: data?.text,
            }),
            true,
            result.validate_by_staff ? 'Close' : 'Validate',
            false,
            true,
          ).then((r) => {
            if (r.isDismissed || r.isConfirmed) {
              setTimeout(() => setIsCameraActive(true), 2000) // Delay of 3 seconds
            }
            if (r.isConfirmed && !result.validate_by_staff) {
              merchantApi.onValidate(data?.text, (r) => {
                notify('Coupon Validated')
                setTimeout(() => setIsCameraActive(true), 1000) // Delay of 3 seconds
              })
            }
          })
        })
      })
    }
  }

  const PopupElement = ({ brand, logo, reward, user, date, status, code }) => {
    let _html = `
    <div>
                    <div class="title reward-title font-semibold text-[30px] mb-5">${brand}</div>
                    <div class="logo mb-5">  
                    <img
                        style="height:105px;width:105px"
                        class="m-auto"
                        src="${logo}"
                        alt=""
                      /></div>
                    <div class="reward reward-description font-semibold text-[17px] text-center mb-5">${reward}</div>
                    <div class="user reward-description font-semibold text-[14px] text-center mb-2">${user}</div>
                    <div class="date reward-description font-medium text-[12px] text-center mb-5">${date}</div>
    `
    if (status) {
      _html += `<div class="status mx-auto mb-5 w-fit px-3 py-2 bg-red-600 text-white text-[14px]">Has Been Collected</div>`
    } else {
      _html += `<div class="status mx-auto mb-5 w-fit px-3 py-2 bg-green-600 text-white text-[14px]">Not Collected</div>`
    }
    _html += `<div class="status font-semibold m-auto w-fit px-3 py-2 border-b-2 border-blue-800 text-blue-800 text-[14px]">${code}</div></div>`
    return _html
  }

  return (
    <Wrapper position="middle">
      {isCameraActive && (
        <div>
          <div className="logo mt-[-6rem] mb-12">
            <img
              src={Logo ?? ''}
              style={{ height: 100 }}
              alt="logo-katchit"
              className="mx-auto"
            />
          </div>

          <QrReader
            delay={1000}
            style={previewStyle}
            onError={handleError}
            onScan={(e) => handleScan(e)}
            constraints={{
              audio: false,
              video: { facingMode: 'environment' },
            }}
          />
          <div className="text-lg text-white font-bold mt-8 text-center">
            Scan the Voucher QR Code
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export default Index
