import {http} from '../config'

const PREFIX = ''

const getVendor = async (coupon) => {
  try {
    let res = await http("json", process.env.REACT_APP_API_URL, false).get(
        `${PREFIX}/vendor/detail?voucher_code=${coupon}`,
    )
    return res.data
  } catch (err) {
    throw err
  }
}
const getValidate = async (coupon) => {
  try {
    let res = await http("json", process.env.REACT_APP_API_URL, false).post(
        `${PREFIX}/vendor/validate`, {
          "voucher_code": coupon
        }
    )
    return res.data
  } catch (err) {
    throw err
  }
}
export {getValidate, getVendor}
